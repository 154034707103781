import React, { Fragment, } from 'react';
import PropTypes from 'prop-types';
import { useFela, } from 'react-fela';
import { parseStyleProps, } from '@haaretz/htz-css-tools';

import { STK_QUERY, } from '@haaretz/graphql';
import Query from '../ApolloBoundary/Query';
import HtzLink from '../HtzLink/HtzLink';
import Debug from '../Debug/Debug';
import TextLink from '../TextLink/TextLink';
import H from '../AutoLevels/H';
import FirstImpressionPlaceholder from './FirstImpressionPlaceholder';
import Zen from '../Zen/Zen';
import { stylesPropType, } from '../../propTypes/stylesPropType';
import WebViewExclude from '../WebViewExclude/WebViewExclude';

// eslint-disable-next-line react/prop-types
const Crosshead = ({ miscStyles, ...props }) => {
  const { css, theme, } = useFela();
  const className = css({
    extend: [
      theme.type(2),
      ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []),
    ],
  });

  return <H className={className} {...props} />;
};

const paragraphStyle = ({ miscStyles, theme, useDefaultType, }) => ({
  marginBottom: '3rem',
  wordWrap: 'break-word',
  extend: [
    ...(useDefaultType ? [
      theme.type(1, { untilBp: 'xl', lines: 5, }),
      theme.type(0, { fromBp: 'xl', lines: 5, }),
    ] : []),
    // Trump all other styles with those defined in `miscStyles`
    ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []),
  ],
});

// eslint-disable-next-line react/prop-types
const P = ({ children, renderFirstImpression, useDefaultType, miscStyles, ...props }) => {
  const className = useFela({ miscStyles, useDefaultType, }).css(paragraphStyle);

  return (
    <Fragment>
      <p className={className} {...props}>
        {children}
      </p>
      {renderFirstImpression ? (
        <WebViewExclude>
          <Zen>
            <FirstImpressionPlaceholder />
          </Zen>
        </WebViewExclude>
      ) : null}
    </Fragment>
  );
};

// eslint-disable-next-line react/prop-types
const Question = ({ children, ...props }) => {
  const { css, theme, } = useFela();
  const className = css({
    fontWeight: '700',
    extend: [ theme.type(1, { untilBp: 'xl', lines: 5, }), theme.type(0, { fromBp: 'xl', lines: 5, }), ],
  });

  return (
    <p className={className} {...props}>
      {children}
    </p>
  );
};

// eslint-disable-next-line react/prop-types
const Ul = ({ children, miscStyles, ...props }) => {
  const { css, theme, } = useFela();
  const className = css({
    marginBottom: '3rem',
    listStylePosition: 'inside',
    listStyleType: 'disc',
    paddingTop: '0',
    paddingBottom: '0',
    paddingInlineStart: '0',
    paddingInlineEnd: '0',
    extend: [
      theme.type(1, { untilBp: 'xl', lines: 5, }),
      theme.type(0, { fromBp: 'xl', lines: 5, }),
      ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []),
    ],
  });

  return (
    <ul className={className} {...props}>
      {children}
    </ul>
  );
};

// eslint-disable-next-line react/prop-types
const Ol = ({ children, miscStyles, ...props }) => {
  const { css, theme, } = useFela();
  const className = css({
    marginBottom: '3rem',
    listStylePosition: 'inside',
    listStyleType: 'decimal-leading-zero',
    paddingTop: '0',
    paddingBottom: '0',
    paddingInlineStart: '0',
    paddingInlineEnd: '0',
    extend: [
      theme.type(1, { untilBp: 'xl', lines: 5, }),
      theme.type(0, { fromBp: 'xl', lines: 5, }),
      ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []),
    ],
  });

  return (
    <ul className={className} {...props}>
      {children}
    </ul>
  );
};

// eslint-disable-next-line react/prop-types
const Strong = ({ children, ...props }) => {
  const className = useFela().css({ fontWeight: 700, });

  return (
    <strong className={className} {...props}>
      {children}
    </strong>
  );
};

// eslint-disable-next-line react/prop-types
export function Stk({ children, assetId: assetid, ...props }) {
  const { css, theme, } = useFela();
  return (
    <span {...props}>
      <Query query={STK_QUERY} ssr={false} variables={{ ids: [ assetid, ], }}>
        {({ data, loading, error, }) => {
          const asset = data && data.assets && data.assets[0];
          if (!asset) {
            return (
              <>
                <span>
                  {children}
                </span>
                {error || !loading ? (
                  <Debug>
                    {`${error ? 'Error' : 'No Asset data'} in Stk query for assetId: ${assetid}`}
                  </Debug>
                ) : null}
              </>
            );
          }
          const isPositive = asset.changePercentage >= 0;
          return (
            <React.Fragment>
              <HtzLink
                className={css({
                  textDecoration: 'underline',
                  textDecorationColor: theme.color('secondary'),
                  ':hover': {
                    color: theme.color('secondary'),
                    textDecoration: 'none',
                  },
                })}
                href={`https://finance.themarker.com/${asset.type}/${asset.id}`}
                target="_blank"
              >
                {children}
              </HtzLink>
              &nbsp;
              <i dir="ltr" className={css({ fontStyle: 'inherit', })}>
                (
                <span
                  className={css({
                    fontWeight: 700,
                    color: isPositive ? theme.color('positive') : theme.color('negative'),
                  })}
                >
                  {`${isPositive ? '+' : ''}${asset.changePercentage}${'%'}`}
                </span>
                &nbsp;
                {asset.value}
                {')'}
              </i>
            </React.Fragment>
          );
        }}
      </Query>
    </span>
  );
}

// eslint-disable-next-line react/prop-types
const Em = ({ children, ...props }) => {
  const className = useFela().css({ fontStyle: 'italic', });

  return (
    <em className={className} {...props}>
      {children}
    </em>
  );
};

// eslint-disable-next-line react/prop-types
const UnderLine = ({ children, ...props }) => {
  const className = useFela().css({
    textDecoration: 'underline',
    textDecorationSkip: 'ink',
  });

  return (
    <span className={className} {...props}>
      {children}
    </span>
  );
};

// eslint-disable-next-line react/prop-types
const Mark = ({ children, ...props }) => {
  const { css, theme, } = useFela();
  const className = css({ backgroundColor: theme.color('paragraph', 'markBg'), });

  return (
    <mark className={className} {...props}>
      {children}
    </mark>
  );
};

/* Util functions */
const extractAttributes = (attrArray, templateFunc) => {
  // List of all the acceptable attributes. value attribute is used by stk
  const whiteList = [ 'class', 'href', 'target', 'id', 'name', 'className', 'assetid', 'onClick', ];
  const attributes = {};
  if (attrArray && attrArray.length > 0) {
    // eslint-disable-line array-callback-return
    attrArray.forEach(attribute => {
      if (whiteList.includes(attribute.key)) {
        const key = attribute.key === 'class' ? 'hasClass' : attribute.key; // Switching 'class' to 'className'.
        attributes[key] = templateFunc && typeof templateFunc === 'function'
          ? templateFunc(attribute.value)
          : attribute.value;
      }
    });
  }
  return attributes;
};

/**
 * These type of paragraphs should ignore any MarginBottom received by the parents.
 */
const whoShouldNotMargin = [ 'question', 'h3', ];

// eslint-disable-next-line no-unused-vars
const shouldMargin = content => {
  if (whoShouldNotMargin.includes(content.tag)) {
    return false;
  }
  if (content.content) {
    for (const element of content.content) {
      if (!shouldMargin(element)) {
        return false;
      }
    }
  }
  return true;
};
// new tags: li ol ul
const getTag = tag => {
  const tagsMap = new Map([
    [ 'p', P, ],
    [ 'a', TextLink, ],
    [ 'strong', Strong, ],
    [ 'question', Question, ],
    [ 'mark', Mark, ],
    [ 'u', UnderLine, ],
    [ 'ul', Ul, ],
    [ 'ol', Ol, ],
    [ 'li', 'li', ],
    [ 'u', UnderLine, ],
    [ 'em', Em, ],
    [ 'span', 'span', ],
    [ 'stk', Stk, ],
    [ 'h3', Crosshead, ],
    [ 'br', () => <br />, ],
  ]);
  return tagsMap.get(tag);
};

const genChildren = (tagElements, templateFunc) => tagElements.map((tag, index) => {
  let children = null;

  if (tag.tag === 'br') {
    children = (<br key={`br=${index}`} />); // eslint-disable-line react/no-array-index-key
  }
  else if (tag.tag !== '#text' && !tag.content && tag.attributes) {
    const Tag = tag.tag;
    children = (<Tag templateFunc={templateFunc} {...extractAttributes(tag.attributes, templateFunc)} />);
  }
  else if (tag.content) {
    children = (<Content key={index} templateFunc={templateFunc} {...tag} />); // eslint-disable-line react/no-array-index-key
  }
  else {
    children = tag.attributes[0].value;
    if (templateFunc && typeof templateFunc === 'function') {
      children = templateFunc(children);
    }
  }

  return children;
});

WrapperTag.propTypes = {
  /** The HTML tag name */
  // eslint-disable-next-line react/no-unused-prop-types
  tag: PropTypes.string.isRequired,
  /** The tag attributes (className, href, etc) */
  // eslint-disable-next-line react/forbid-prop-types
  attributes: PropTypes.objectOf(PropTypes.string), // eslint-disable-line react/no-unused-prop-types
  /** The paragraphs content.<br/>
   * If the value of content contains children, they must have this exact prop structure
   * {tag, attributes, content}.
   */
  // eslint-disable-next-line react/forbid-prop-types
  content: PropTypes.array.isRequired, // eslint-disable-line react/no-unused-prop-types
  /** Should the Paragraph render a firstImpression placeholder after every p tag */
  renderFirstImpression: PropTypes.bool,

  miscStyles: stylesPropType,
  /** Should the paragraph have the default type styles (difficult to overwrite otherwise) */
  useDefaultType: PropTypes.bool,
  /** function for replacing text-placeholder */
  templateFunc: PropTypes.func,
};

WrapperTag.defaultProps = {
  attributes: null,
  miscStyles: null,
  renderFirstImpression: false,
  useDefaultType: true,
  templateFunc: null,
};

function WrapperTag({
  tag: tagName,
  content: tagElements,
  attributes,
  renderFirstImpression,
  useDefaultType,
  miscStyles,
  templateFunc,
}) {
  /**
   * Check if the Tag has a class names 'bg-brand--d'.
   * If so, it means that the Tag is actually `<mark />` (That's how the FCKEditor translates it).
   */
  // if (attributes.hasClass && attributes.hasClass === 'bg-brand--d') {
  //   tagName = 'mark'; // eslint-disable-line no-param-reassign
  // }
  // else
  if (attributes.id) {
    /**
     * In case of an anchor inside the paragraph,
     * we don't need a new component but just a `<span />` with the anchors ID.
     */
    tagName = 'span'; // eslint-disable-line no-param-reassign
  }
  const Tag = getTag(tagName);
  if (tagName === 'a') {
    /**
     * In case that the paragraph tree continues inside the Link component,
     * we send it this component (the `<Paragraph />`) recursive function as the content,
     * so the Link component will continue the building of the paragraph.
     */
    attributes.content = genChildren(tagElements, templateFunc); // eslint-disable-line no-param-reassign
  }
  if (tagName === 'br') return Tag;

  return Tag ? (
    <Tag
      {...attributes}
      {...([ 'p', 'h3', 'ol', 'ul', ].includes(tagName) ? { miscStyles, } : {})}
      {...(tagName === 'p' ? { renderFirstImpression, useDefaultType, } : {})}
    >
      {genChildren(tagElements, templateFunc)}
    </Tag>
  ) : null;
}

Content.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  content: PropTypes.array.isRequired, // eslint-disable-line react/no-unused-prop-types
  tag: PropTypes.string.isRequired,
  /** Should use the default paragraph typescale. Defaults to true */
  useDefaultType: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  attributes: PropTypes.array, // eslint-disable-line react/no-unused-prop-types
  renderFirstImpression: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
  templateFunc: PropTypes.func, // function for replacing text-placeholder
  miscStyles: stylesPropType,
};

Content.defaultProps = {
  attributes: null,
  renderFirstImpression: false,
  useDefaultType: true,
  miscStyles: null,
  templateFunc: null,
};

/* Inner components */
function Content({
  content,
  attributes,
  tag,
  renderFirstImpression,
  miscStyles,
  useDefaultType,
  templateFunc, }) {
  // const { attributes, tag, renderFirstImpression, miscStyles, } = content;
  const attributesObject = extractAttributes(attributes, templateFunc);
  return (
    <WrapperTag
      tag={tag}
      attributes={attributesObject}
      content={content}
      useDefaultType={useDefaultType}
      miscStyles={miscStyles}
      renderFirstImpression={renderFirstImpression}
      templateFunc={templateFunc}
    />
  );
}

/**
 * This paragraph component receives an object with it's attributes and children
 * (which comes with their own children and so on), and it builds itself recursively.
 *
 * @param {Object} props
 */
export default function Paragraph(props) {
  const { css, } = useFela();

  return (
    <>
      <Debug>
        <div className={css({ color: 'orangered', fontSize: '3rem', })}>Component Paragraph is deprecated, please, use HtmlNode.</div>
      </Debug>
      <Content {...props} />
    </>
  );
}

/** Components props */
Paragraph.propTypes = {
  /** The HTML tag name */
  // eslint-disable-next-line react/no-unused-prop-types
  tag: PropTypes.string.isRequired,
  /** The tag attributes (className, href, etc) */
  // eslint-disable-next-line react/forbid-prop-types
  attributes: PropTypes.array, // eslint-disable-line react/no-unused-prop-types
  /** The paragraphs content.<br/>
   * If the value of content contains children, they must have this exact prop structure
   * {tag, attributes, content}.
   */
  // eslint-disable-next-line react/forbid-prop-types
  content: PropTypes.array.isRequired, // eslint-disable-line react/no-unused-prop-types
  /** Should the Paragraph render a firstImpression placeholder after every p tag */
  renderFirstImpression: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
  /** Should use the default paragraph typescale. Defaults to true */
  useDefaultType: PropTypes.bool,
  miscStyles: stylesPropType,
  /** function for replacing text-placeholder */
  templateFunc: PropTypes.func,
};

Paragraph.defaultProps = {
  attributes: null,
  renderFirstImpression: false,
  useDefaultType: true,
  miscStyles: null,
  templateFunc: null,
};
