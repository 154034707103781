// @flow
/* global document navigator */
import { STK_QUERY, } from '@haaretz/graphql';
import React, { useEffect, useRef, } from 'react';
import { useApolloClient, } from 'react-apollo';
import { useFela, } from 'react-fela';
import { parseStyleProps, } from '@haaretz/htz-css-tools';
import H from '../AutoLevels/H';
import { inlineLinkStyle, } from '../TextLink/TextLink';
import WebViewExclude from '../WebViewExclude/WebViewExclude';
import Zen from '../Zen/Zen';
import FirstImpressionPlaceholder from './FirstImpressionPlaceholder';
import Paragraph from '../Paragraph/Paragraph';
import useOneTime from '../../hooks/useOneTime';
import { useEventTracker, } from '../../utils/EventTracker';

/* Util functions */
const extractAttributes = (attrArray, templateFunc) => {
  // List of all the acceptable attributes. value attribute is used by stk
  const whiteList = [ 'class', 'href', 'target', 'id', 'name', 'className', 'assetid', 'onClick', 'content', 'tag', ];
  const attributes = {};
  if (attrArray && attrArray.length > 0) {
    // eslint-disable-line array-callback-return
    attrArray.forEach(attribute => {
      if (whiteList.includes(attribute.key)) {
        const key = attribute.key === 'class' ? 'hasClass' : attribute.key; // Switching 'class' to 'className'.
        attributes[key] = templateFunc && typeof templateFunc === 'function'
          ? templateFunc(attribute.value)
          : attribute.value;
      }
    });
  }
  return attributes;
};

function replaceAll(str: string, mapObj: { [key: string]: string, }) {
  if (typeof str !== 'string') {
    return '';
  }
  const replaceSrt = new RegExp(Object.keys(mapObj).join('|'), 'gi');

  return str.replace(replaceSrt, matched => mapObj[matched.toLowerCase()] || matched);
}

// $FlowFixMe
type ComponentProps = React.PropsWithChildren<{
  className?: string,
  miscStyles?: Object,
}>

// $FlowFixMe
const Crosshead = React.forwardRef < ComponentProps >(({
  className,
  miscStyles,
  ...props
}: ComponentProps, ref) => {
  const { css, theme, } = useFela();

  const styles = css({
    extend: [
      theme.type(2),
      ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []),
    ],
  });

  return <H className={`${styles}${className ? ` ${className}` : ''}`} {...props} ref={ref} />;
});


// eslint-disable-next-line react/prop-types
// $FlowFixMe
const Ul = React.forwardRef < ComponentProps >(({
  children,
  className,
  miscStyles,
  ...props
}: ComponentProps, ref) => {
  const { css, theme, } = useFela();
  const styles = css({
    marginBottom: '3rem',
    listStylePosition: 'inside',
    listStyleType: 'disc',
    paddingTop: '0',
    paddingBottom: '0',
    paddingInlineStart: '0',
    paddingInlineEnd: '0',
    extend: [
      theme.type(1, { untilBp: 'xl', lines: 5, }),
      theme.type(0, { fromBp: 'xl', lines: 5, }),
      ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []),
    ],
  });

  return (
    <ul className={`${styles}${className ? ` ${className}` : ''}`} {...props} ref={ref}>
      {children}
    </ul>
  );
});

// eslint-disable-next-line react/prop-types
// $FlowFixMe
const Ol = React.forwardRef < ComponentProps >(({
  children,
  className,
  miscStyles,
  ...props
}: ComponentProps, ref) => {
  const { css, theme, } = useFela();
  const styles = css({
    marginBottom: '3rem',
    listStylePosition: 'inside',
    listStyleType: 'decimal-leading-zero',
    paddingTop: '0',
    paddingBottom: '0',
    paddingInlineStart: '0',
    paddingInlineEnd: '0',
    extend: [
      theme.type(1, { untilBp: 'xl', lines: 5, }),
      theme.type(0, { fromBp: 'xl', lines: 5, }),
      ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []),
    ],
  });

  return (
    <ul className={`${styles}${className ? ` ${className}` : ''}`} {...props} ref={ref}>
      {children}
    </ul>
  );
});

// $FlowFixMe
type ParagraphProps = React.PropsWithChildren<{
  renderFirstImpression?: boolean,
} & ComponentProps>;

// eslint-disable-next-line react/prop-types
// $FlowFixMe
const P = React.forwardRef < ParagraphProps >(({
  children,
  renderFirstImpression = false,
  className,
  miscStyles,
  ...props
}: ParagraphProps, ref) => {
  const { css, theme, } = useFela({ miscStyles, });

  const styles = css({
    marginBottom: '3rem',
    wordWrap: 'break-word',
    extend: [
      theme.type(1, { untilBp: 'xl', lines: 5, }),
      theme.type(0, { fromBp: 'xl', lines: 5, }),
      ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []),
    ],
  });

  return (
    <>
      <p className={`${styles}${className ? ` ${className}` : ''}`} {...props} ref={ref}>
        {children}
      </p>
      {renderFirstImpression ? (
        <WebViewExclude>
          <Zen>
            <FirstImpressionPlaceholder />
          </Zen>
        </WebViewExclude>
      ) : null}
    </>
  );
});

const tagsMap = new Map([
  [ 'ul', Ul, ],
  [ 'ol', Ol, ],
  [ 'p', P, ],
  [ 'h3', Crosshead, ],
]);

interface HtmlNodeProps {
  tag: string,
  content: string,
  className?: string;
  miscStyles?: Object,
  renderFirstImpression?: boolean,
  attributes?: any[],
  templateFunc?: (text: string) => string,
}

type RenderStkArgs = {
  css: Function,
  data?: Object,
  error?: Object,
  theme: Object,
}

function renderStk({ data, error, css, theme, }: RenderStkArgs) {
  if (error) {
    console.error(error);
    return '';
  }

  const asset = data && data.assets && data.assets[0];

  if (!asset) {
    return '';
  }

  const container = document.createElement('i');

  const containerClassNames = css({
    fontStyle: 'inherit',
    direction: 'ltr',
    display: 'inline-block',
    paddingInlineEnd: '1px',
  }).split(' ');
  container.classList.add(...containerClassNames);

  const isPositive = asset.changePercentage >= 0;

  const percentageElement = document.createElement('span');

  const percentageClassNames = css({
    fontWeight: 700,
    color: isPositive ? theme.color('positive') : theme.color('negative'),
  }).split(' ');

  percentageElement.classList.add(...percentageClassNames);
  percentageElement.innerHTML = `${isPositive ? '+' : ''}${asset.changePercentage}${'%'}`;

  container.appendChild(document.createTextNode('\u00A0('));
  container.appendChild(percentageElement);
  container.appendChild(document.createTextNode(`\u00A0${asset.value})\u00A0`));

  return container.outerHTML;
}

export default function HtmlNode(props: HtmlNodeProps) {
  const {
    tag,
    content,
    className,
    miscStyles,
    renderFirstImpression,
    attributes,
    templateFunc,
  } = props;

  const { biAction, } = useEventTracker();
  const ref = useRef < any >();
  const client = useApolloClient();
  const { css, theme, } = useFela();
  const attributesObject = extractAttributes(attributes, templateFunc);

  useEffect(() => {
    const { current, } = ref;

    if (current) {
      const STKs = Array.from(current.querySelectorAll('span[data-stk]'));

      if (STKs.length) {
        STKs.forEach(item => {
          client
            .query({ query: STK_QUERY, variables: { ids: [ item.dataset.stk, ], }, })
            .then(response => {
              // eslint-disable-next-line no-param-reassign
              item.innerHTML = renderStk({ ...response, css, theme, });
            });
        });
      }
    }
  }, [ client, css, theme, ]);

  useOneTime(true, () => {
    const { current, } = ref;

    const elementsWithBI = current ? Array.from(current.querySelectorAll('[data-bidata]')) : [];

    if (elementsWithBI.length) {
      elementsWithBI.forEach(item => {
        item.addEventListener('click', () => {
          const biData = JSON.parse(decodeURIComponent(item.getAttribute('data-bidata')));
          const { action: actionCode, feature, featureType, campaignName, campaignDetails, } = biData;
          biAction({ actionCode, feature, featureType, campaignName, campaignDetails, });
        });
      });
    }
  });

  if (Array.isArray(props.content)) {
    return <Paragraph {...props} />;
  }

  const cssMap = Object.freeze({
    '#em#': css({ fontStyle: 'italic', }),
    '#strong#': css({ fontWeight: 700, }),
    '#a#': css(inlineLinkStyle),
    '#mark#': css({ backgroundColor: theme.color('paragraph', 'markBg'), }),
    '#u#': css({
      textDecoration: 'underline',
      textDecorationSkip: 'ink',
    }),
  });

  const Component = (tagsMap.has(tag) ? tagsMap.get(tag) : tag) || 'p';

  if (Component === 'br') return <Component />;

  let html = replaceAll(content, cssMap);

  if (typeof templateFunc === 'function') {
    html = templateFunc(html);
  }

  return (
    <>
      <Component
        {...attributesObject}
        className={className}
        renderFirstImpression={renderFirstImpression}
        ref={ref}
        miscStyles={miscStyles}
        dangerouslySetInnerHTML={{
          __html: html,
        }}
      />
    </>
  );
}

HtmlNode.defaultProps = {
  attributes: null,
  miscStyles: null,
  renderFirstImpression: false,
  className: null,
  templateFunc: null,
};
